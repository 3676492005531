import Util from "@/utils/utils";
import { getList, getExport } from "./api";

export default {
  name: "terminalInspectionRecord",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/agentVerificationActivities",
          name: "代理商市场活动",
          title: "代理商市场活动"
        }
      ],
      hideOrShowSize: true,
      columns: [
        {
          label: "营销中心",
          prop: "isSpeclalShop",
          width: 200,
        },
        {
          label: "办事处",
          prop: "storeName",
          width: 200,
        },
        {
          label: "申请活动时间",
          prop: "storeCode",
          width: 200,
        },
        {
          label: "活动承接代理(运营)商",
          prop: "checkUserName",
          width: 200,
        },
        {
          label: "活动主题",
          prop: "checkUserCode",
          width: 200,
        },
        {
          label: "活动地点",
          prop: "dutyUserName",
          width: 200,
        },
        {
          label: "参与分销商",
          prop: "dutyUserCode",
          width: 200,
        },
        {
          label: "各项投入合计",
          prop: "checkTime",
          width: 200,
        },
        {
          label: "待制作广告公司",
          prop: "checkResult",
          width: 200,
        },
        {
          label: "审批状态",
          prop: "clientSource",
          width: 200,
        },
        {
            label: "提报人",
            prop: "clientSource",
            width: 200,
        },
        {
            label: "提报时间",
            prop: "clientSource",
            width: 200,
        },
        {
          label: "操作",
          slot: "btnClick",
          ellipsis: true,
          fixed:'right',
          width: 150,
        },
      ],
      tableData: [
          {
            "clientSource": "信小蜜",// --系统来源
            "checkUserCode": "20042372",// --检查人编码
            "fileMapperPath": "https://ctstest.hisense.com/oa_app//publicPictures/202103/de55188707f249539eb6adc36f7d48f1.jpg",// --手写字地址
            "dutyUserName": "李坚真", //--责任人名称
            "checkTime": "2021-03-26 15:02:42.828", //--检查时间
            "dutyUserCode": "30040106",//--责任人编码
            "checkUserName": "孙玉娥", //--检查人名称编码
            "storeName": "利群海琴广场",//--门店名称
            "id": "470024359710",//--检查结果ID,用户查看明细
            "checkResult": "F", //--检查结果
            "isSpeclalShop": "普通门店", //--门店类型
            "storeCode": "708111507" //--门店编码
          }
      ],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      ssearchList: {
        storeCode: "",
        storeName: "",
        'params.pageNo':1,
        'params.pageSize':10,
        checkUserCode:'', // 检查人编码
        checkUserName:'', // 检查人员姓名
        checkResult:'' , // T合格 F不合格
        dutyUserCode:'', // 责任人编码
        dutyUserName:'', // 责任人姓名
        isSpeclalShop:'', // T专卖店 F普通店
        serviceCode:'findResultList'
      },
      searchNumber: 0,
      hideshow: false,
      isLoading: false,
      onluyOneMessage: false,
      imgURL:[],
      tableLoading:false
    };
  },
  watch: {
    "ssearchList.checkUserCode": function(val) {
      this.ssearchList.checkUserCode = val.trim();
    },
    "ssearchList.storeName": function(val) {
      this.ssearchList.storeName = val.trim();
    },
    "ssearchList.storeCode": function(val) {
      this.ssearchList.storeCode = val.trim();
    },
    "ssearchList.checkUserName": function(val) {
      this.ssearchList.checkUserName = val.trim();
    },
    "ssearchList.dutyUserCode": function(val) {
      this.ssearchList.dutyUserCode = val.trim();
    },
    "ssearchList.dutyUserName": function(val) {
      this.ssearchList.dutyUserName = val.trim();
    }
  },
  mounted() {},
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {
    fun_date(num) {
      var date1 = new Date();
      var yuefen =
        date1.getMonth() + 1 < 10
          ? "0" + Number(date1.getMonth() + 1)
          : date1.getMonth();
      var ri =
        date1.getDate() + 1 < 10 ? "0" + date1.getDate() : date1.getDate();
      var time1 = date1.getFullYear() + "-" + yuefen + "-" + ri;
      this.ssearchList.checkEnd = time1;

      var date2 = new Date(date1);
      date2.setDate(date1.getDate() + num);
      var yu =
        date2.getMonth() + 1 < 10
          ? "0" + Number(date2.getMonth() + 1)
          : date2.getMonth();
      var riq =
        date2.getDate() + 1 < 10 ? "0" + date2.getDate() : date2.getDate();
      var time2 = date2.getFullYear() + "-" + yu + "-" + riq;
      this.ssearchList.checkStart = time2;
    },
    exportClick() {
      let data = this.ssearchList;
      data["params.pageSize"] = 300000;
      data["params.pageNo" ]= 1;
      data.serviceCode ='exportQueryRowsXtw'
      data.containsPicture = true
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "终端管理记录.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },

    pageSearch(pager) {
      this.ssearchList["params.pageNo"] = pager.pageNo;
      this.ssearchList["params.pageSize"] = pager.pageSize;
      this.pager.pageNo = pager.pageNo
      this.pager.pageSize = pager.pageSize
      this.tablelist();
    },
    tablelist() {
      let data = this.ssearchList;
      this.tableLoading = true
      getList(data).then(res => {
        if(res.data.returnCode==100) {
          this.tableData = res.data.returnData.list;
          if (res.data.returnData.list) {
            this.searchNumber = res.data.returnData.totalCount;
            this.pager.count = res.data.returnData.totalCount;
          }
          if(this.tableData && this.tableData.length > 0) {
            this.tableData.forEach(item=>{
              if(item.checkResult == 'T') {
                item.checkResult = '合格'
              }
              if(item.checkResult == 'F') {
                item.checkResult = '不合格'
              }
            })
          }
         this.tableLoading = false
        } else {
          this.$message.warning(res.data.returnMsg)
          this.tableLoading = false
        }
      });
    },
    // 查看签名
    openSign(row) {
      this.onluyOneMessage = true
      this.imgURL = row.fileMapperPath.split(',')
    },
    
    // 查看详情
    toDetail(row) {
      let routeUrl = this.$router.resolve({
        query: { id: row.id },
      });
      window.open(routeUrl.href, "_blank");
    },

    hideOrShow(data) {
      this.hideOrShowSize = data;
    },
    // 重置
    reset() {
      this.ssearchList = {
        storeCode: "",
        storeName: "",
        checkUserCode: "",
        checkUserName: "",
        dutyUserCode: "",
        dutyUserName:"",
        checkResult: "",
        isSpeclalShop: "",
        // checkStart: "",
        // checkEnd: null,
        "params.pageNo": 1,
        "params.pageSize":10,
        serviceCode:'findResultList'
      };
      this.tablelist();
    },

    // 分页
    pagesiezes(data, pageSize) {
      this.ssearchList["params.pageNo"] = data;
      this.ssearchList["params.pageSize"] = pageSize;
      this.tablelist();
    },
    handleChange(e) {
    },
    // 是否是品牌园
    handleChangePP(e) {
    },
    onChangecheckStart(e, time) {
      this.ssearchList.checkStart = time;
    },
    onChangecheckEnd(e, time) {
      this.ssearchList.checkEnd = time;
    },
    searchClick() {
      this.tablelist();
    }
  }
};
