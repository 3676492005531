import http from "@/utils/request";

// 获取列表
export function getList(data) {
    return http({
        // url: '/app/storeCheck/findResultList.do',
        url: 'cts/ctsApi.nd',
        method: 'post',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    })
}

// 获取详情
export function getDeatil(data) {
    return http({
        url: 'cts/ctsApi.nd',
        method: 'post',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    })
}


export function getExport(data) {
    return http({
        url: 'cts/ctsApi.nd',
        method: 'get',
        params:data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        responseType: 'blob'
    })
}



